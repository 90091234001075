@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import '@algolia/autocomplete-theme-classic';

@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
    /*@apply bg-nIndigo-100;*/
    min-height: 100%;
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
}

@layer base {
    /*a {*/
    /*    @apply text-blue-900;*/
    /*    @apply underline;*/
    /*}*/
}

@media only screen and (max-width: 576px) {
    body {
        font-size: 14px;
    }
}

@media only screen {
    .block-shadow {
        -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
    }
}

.text-dialog li {
    margin-bottom: 1rem;

}


.text-dialog h2 {
    margin-bottom: 1.5rem;
    margin-top: 3.5rem;
}

.nav-link.active {
    color: black;
    border-bottom-color: #4C8BF5;
}

@media only screen and (max-width: 576px) {
    .nav-link.active {
        @apply bg-blue-600;
        @apply border-blue-900;
        @apply text-blue-900;
    }
}

.numbered-list, .numbered-list-top {
    counter-reset: item;
}

.numbered-list-top h2:before {
    content: counters(item, ".") ". ";
    counter-increment: item
}

.numbered-list li:before {
    content: counters(item, ".") ". ";
    counter-increment: item
}

.border-red-500,
.react-select__control {
    border-color: red !important;
}


.react-select-container {

}


/*search styles*/
:root {
    --aa-primary-color-rgb: 85, 96, 171;
    --aa-primary-color-alpha: 0;
    --aa-panel-border-color-rgb: 225, 231, 235;
    --aa-panel-border-color-alpha: 1;
    --aa-input-border-color-rgb: 225, 231, 235;
    --aa-input-border-color-alpha: 1;

    --aa-input-background-color-rgb: 250, 252, 253;
    --aa-input-background-color-alpha: 1;

    --aa-selected-color-rgb: 255, 244, 245;
    --aa-selected-color-alpha: 1;
}

.aa-Form {
    border-radius: 40px;
    overflow: hidden;
}

.aa-Item {
    padding-top: 7px;
    padding-bottom: 7px;

}

.aa-Input {
    background-color: rgb(250, 252, 253);
    padding-left: 0;
    padding-right: 0;
}

.aa-Input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.aa-SubmitIcon {
    margin-right: 0;
}

.aa-Panel {
    border-radius: 20px;
    z-index: 999;
}

.aa-Autocomplete button {
    border-radius: 40px;
    font-size: 14px;
}

.aa-DetachedCancelButton {
    border-radius: 8px;
    font-size: 14px;
}

.ck .ck-reset .ck-editor .ck-rounded-corners {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.ck.ck-toolbar {
    border-color: transparent !important;
}

.ck.ck-editor__editable_inline {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-top-color: #E2EAEE !important;
}

.cke_bottom {
    border-radius: 0;
}

.ck-content a,
.comment a {
    text-decoration: underline;
    @apply text-blue-900;
}

.ck-content ol,
.comment ol {
    list-style-type: decimal;
    list-style-position: inside;
    margin-left: 15px;
}

.ck-content ul,
.comment ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 15px;
}

.ck-content {
    font-size: 14px;
}

.highlighted-comment {
    animation-name: color_change;
    animation-duration: 1s;
    animation-iteration-count: initial;
}

@keyframes color_change {
    from {
        background-color: yellow;
    }

    to {
        background-color: transparent;
    }
}


.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
    padding-top: 56.25%;    /*  9 by 16 */
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/*@each $embed-responsive-aspect-ratio in $embed-responsive-aspect-ratios {*/
/*  $embed-responsive-aspect-ratio-x: nth($embed-responsive-aspect-ratio, 1);*/
/*  $embed-responsive-aspect-ratio-y: nth($embed-responsive-aspect-ratio, 2);*/

/*  .embed-responsive-#{$embed-responsive-aspect-ratio-x}by#{$embed-responsive-aspect-ratio-y} {*/
/*    &::before {*/
/*      padding-top: percentage($embed-responsive-aspect-ratio-y / $embed-responsive-aspect-ratio-x);*/
/*    }*/
/*  }*/
/*}*/
