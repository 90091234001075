@font-face {
  font-family: 'napo';
  src:  url('fonts/napo.eot?kfdazf');
  src:  url('fonts/napo.eot?kfdazf#iefix') format('embedded-opentype'),
    url('fonts/napo.ttf?kfdazf') format('truetype'),
    url('fonts/napo.woff?kfdazf') format('woff'),
    url('fonts/napo.svg?kfdazf#napo') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'napo' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-turn-down-right:before {
  content: "\e900";
}
.icon-angle-down:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e902";
}
.icon-AGRO:before {
  content: "\e903";
}
.icon-BUSINESS:before {
  content: "\e904";
}
.icon-category-culture:before {
  content: "\e905";
}
.icon-category-entertainment:before {
  content: "\e906";
}
.icon-category-food:before {
  content: "\e907";
}
.icon-category-help:before {
  content: "\e908";
}
.icon-LIFE:before {
  content: "\e909";
}
.icon-SCIENCE:before {
  content: "\e90a";
}
.icon-SPORT:before {
  content: "\e90b";
}
.icon-category-style:before {
  content: "\e90c";
}
.icon-category-travel:before {
  content: "\e90d";
}
.icon-comment:before {
  content: "\e90e";
}
.icon-chat:before {
  content: "\e90f";
}
.icon-dislike:before {
  content: "\e910";
}
.icon-edit:before {
  content: "\e911";
}
.icon-gear:before {
  content: "\e912";
}
.icon-home:before {
  content: "\e913";
}
.icon-like:before {
  content: "\e914";
}
.icon-news:before {
  content: "\e915";
}
.icon-profile:before {
  content: "\e916";
}
.icon-share:before {
  content: "\e917";
}
.icon-star:before {
  content: "\e918";
}
.icon-trash:before {
  content: "\e919";
}
.icon-edit-solid:before {
  content: "\e91a";
}
.icon-edit-outline:before {
  content: "\e91b";
}
